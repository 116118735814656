import { createAvatar } from "@dicebear/core";
import { shapes } from "@dicebear/collection";

export function getAvatarUrl(seed?: string) {
  const modifiedSeed = seed?.replace(/[^A-Z0-9 ]/gi, "").replace(/ /g, "") ?? "default";

  const avatar = createAvatar(shapes, {
    seed: modifiedSeed,
  });

  return avatar.toDataUri();
}
