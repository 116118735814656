import type { Button } from "#ui/types";

export interface DcPricingPlan {
  id: DcPlan;
  title: string;
  description?: string;
  price: string;
  discountedPrice?: string;
  yearlyPrice: string;
  discountedYearlyPrice?: string;
  cycle: string;
  highlight: boolean;
  badge?: string;
  features: string[];
  button: Button & { click?: () => void; };
}

export enum DcPlan {
  Trial = "Trial",
  Essential = "Essential",
  Pro = "Pro",
  Enterprise = "Enterprise",
}

export enum DcPlanCycle {
  Monthly,
  Annual,
}
