import { DcEndPoints } from "docuchatcommontypes";
import type { DcDocument, UserDocument } from "docuchatcommontypes";

export const useDocumentStore = defineStore("document", () => {
  const t = useNuxtApp().$i18n.t;

  // State
  const documentsFetched = ref(false);
  const documents: Ref<UserDocument[]> = ref([]);

  // Fetch documents
  async function fetchDocuments(useCache = false) {
    if (useCache && documentsFetched.value)
      return;

    const endpoint = DcEndPoints.GetUserDocuments();
    const response = await useApi(endpoint);

    if (response.success) {
      documents.value = response.content.documents;
      documentsFetched.value = true;
    }
    else {
      throw createError(response.message
        ?? t("common.genericError"));
    }
  }

  // Helpers
  function getDocumentFromCacheById(id: string) {
    const doc = documents.value.find(f => f.document.id === id)?.document;
    if (doc)
      return doc;
    else
      throw createError(t("common.genericError"));
  }

  function getDocumentsFromCacheByIds(ids: string[]) {
    const docs: DcDocument[] = [];

    for (const id of ids) {
      const doc = getDocumentFromCacheById(id);
      docs.push(doc);
    }

    return docs;
  }

  function $reset() {
    documentsFetched.value = false;
    documents.value = [];
  }

  return {
    documents,
    documentsFetched,

    fetchDocuments,
    getDocumentFromCacheById,
    getDocumentsFromCacheByIds,

    $reset,
  };
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useDocumentStore, import.meta.hot));
