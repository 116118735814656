import type { CompletionModel } from "docuchatcommontypes";
import type { UsageAndLimit } from "@/types/limits";

export function useLimits() {
  // Data
  const limits = computed(() => useUserStore().plan?.limits);
  const usage = computed(() => useUserStore().usage);

  // Getters
  const limitsRenewAt = computed(() => usage.value?.usageTermEndDate ? new Date(usage.value?.usageTermEndDate) : new Date());

  const chatbots = computed<UsageAndLimit>(() => ({
    limit: limits.value?.maximumChatBots || 0,
    used: usage.value?.usedChatBots || 0,
    left: (limits.value?.maximumChatBots || 0) - (usage.value?.usedChatBots || 0),
  }));

  const canCreateChatbot = computed<boolean>(() => chatbots.value.left > 0);

  const questionCredits = computed<UsageAndLimit>(() => {
    const totalUsedCredits = Object.entries(usage.value?.usedQuestionsThisUsageTerm || {}).reduce((total, [model, usedQuestions]) => {
      const modelPrice = limits.value?.allowedModelsAndPrices[model as CompletionModel] || 0;
      return total + modelPrice * usedQuestions;
    }, 0);

    return {
      limit: limits.value?.maximumQuestionCreditsPerMonth || 0,
      used: totalUsedCredits,
      left: (limits.value?.maximumQuestionCreditsPerMonth || 0) - totalUsedCredits,
    };
  });

  const questions = computed<Map<CompletionModel, UsageAndLimit>>(() => {
    const result = new Map<CompletionModel, UsageAndLimit>();

    Object.entries(limits.value?.allowedModelsAndPrices || {}).forEach(([model, price]) => {
      if (price !== 0) {
        const limit = questionCredits.value.limit / price;
        const used = questionCredits.value.used / price;
        const left = questionCredits.value.left / price;
        result.set(model as CompletionModel, {
          limit,
          used,
          left,
        });
      }
      else {
        throw createError(`Invalid price for model ${model}.`);
      }
    });

    return result;
  });

  const pageProcessing = computed<UsageAndLimit>(() => ({
    limit: limits.value?.pageProcessingPerMonth || 0,
    used: usage.value?.usedPageProcessingThisUsageTerm || 0,
    left: (limits.value?.pageProcessingPerMonth || 0) - (usage.value?.usedPageProcessingThisUsageTerm || 0),
  }));
  const canProcessPages = computed<boolean>(() => pageProcessing.value.left > 0);

  const cloudStorage = computed<UsageAndLimit>(() => ({
    limit: limits.value?.maximumCloudStorage || 0,
    used: usage.value?.usedCloudStorage || 0,
    left: (limits.value?.maximumCloudStorage || 0) - (usage.value?.usedCloudStorage || 0),
  }));
  const canUseCloudStorage = computed<boolean>(() => cloudStorage.value.left > 0);

  const canWhiteLabel = computed(() => limits.value?.whiteLabelAllowed);

  const canUseTeamFeatures = computed(() => !!limits.value?.canUseOrganizationFeatures);

  const modelsAllowed = computed<CompletionModel[]>(() => Object.keys(limits.value?.allowedModelsAndPrices || {}) as unknown as CompletionModel[]);

  const modelPricing = computed<Record<CompletionModel, number>>(() => limits.value?.allowedModelsAndPrices ?? {} as Record<CompletionModel, number>);

  return {
    limitsRenewAt,
    chatbots,
    canCreateChatbot,
    questions,
    questionCredits,
    pageProcessing,
    canProcessPages,
    cloudStorage,
    canUseCloudStorage,
    canWhiteLabel,
    canUseTeamFeatures,
    modelsAllowed,
    modelPricing,
  };
}
