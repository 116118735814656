import { DcEndPoints, OrganizationUserRole } from "docuchatcommontypes";
import type { AccountUsages, OrganizationData, OrganizationUser, User } from "docuchatcommontypes";
import type { PlanData } from "docuchatcommontypes/CommonTypes/PlanData";
import { DcPlan, DcPlanCycle } from "@/types/plan";

export const useUserStore = defineStore("user", () => {
  const t = useNuxtApp().$i18n.t;

  // State
  const isAuthenticated = computed<boolean>(() => !!useAuth().data.value);
  const isUserDataFetched = ref(false);

  // Data
  const user = ref<User>();
  const organization = ref<OrganizationData>();
  const organizationUsers = ref<OrganizationUser[]>();
  const plan = ref<PlanData>();
  const usage = ref<AccountUsages>();
  const planCycleEndsAt = ref<Date>();
  const planWillRenew = ref<boolean>();

  // Getters
  const avatarUrl = computed(() => getAvatarUrl(user.value?.id ?? "default"));
  const isPlanExpired = computed(() => plan.value?.name?.trim().toLowerCase() === "expired");
  const isPlanAnnual = computed(() => plan.value?.durationMonths === 12);
  const isOnTrial = computed(() => plan.value?.name?.trim().toLowerCase() === "trial");
  const isOnCustomPlan = computed(() => plan.value?.name ? plan.value?.name?.toLowerCase().includes("custom") : false);
  const isAdmin = computed(() => user.value?.organizationUserRole === OrganizationUserRole.SuperAdmin
    || user.value?.organizationUserRole === OrganizationUserRole.Admin);
  const canUseApp = computed(() => user.value?.isPlanChosen && !isPlanExpired.value);

  // Fetch actions
  async function fetchUserData(useCache = false, isPostCheckout = false) {
    if (useCache && isUserDataFetched.value)
      return;

    const endpoint = DcEndPoints.GetUserInfo({ isPostCheckout });
    const response = await useApi(endpoint);

    if (response.success) {
      user.value = response.content.user;
      organization.value = response.content.organizationData;
      plan.value = response.content.planData;
      usage.value = response.content.accountUsages;
      planCycleEndsAt.value = response.content.planPaymentCycleEndsAt
        ? new Date(response.content.planPaymentCycleEndsAt)
        : undefined;
      planWillRenew.value = response.content.planWillRenew;

      isUserDataFetched.value = true;
    }
    else {
      // We need userData to be fetched before we allow access to the app
      throw createError({
        message: response.message
        ?? t("common.genericError"),
        fatal: true,
      },
      );
    }
  }

  async function fetchAccountUsages() {
    const endpoint = DcEndPoints.GetAccountUsages();
    const response = await useApi(endpoint);

    if (response.success)
      usage.value = response.content.usage;

    // We don't throw an error here because this is not a critical request and already logged in the backend
  }

  async function fetchTeamMembers() {
    // Only to overcome the type error for the org id
    if (!organization.value?.id)
      return;

    const endpoint = DcEndPoints.GetOrganizationUsers({ organizationId: organization.value.id });
    const response = await useApi(endpoint);

    if (response.success) {
      organizationUsers.value = response.content.users;
    }
    else {
      throw createError(response.message
        ?? t("common.genericError"));
    }
  }

  async function resetPassword() {
    if (!user.value?.emailorUserName)
      throw createError(t("welcome.resetPasswordPage.validation.email"));

    const endpoint = DcEndPoints.SendPasswordResetEmail({ email: user.value?.emailorUserName });
    const response = await useApi(endpoint);

    if (!response.success) {
      throw createError(response.message
        ?? t("common.genericError"));
    }
  }

  // Plan actions
  async function getBillingPortalUrl() {
    const endpoint = DcEndPoints.GetBillingPortalUrl();
    const response = await useApi(endpoint);

    if (!response.success) {
      throw createError(response.message
        ?? t("common.genericError"));
    }

    return response.content.redirectUrl;
  }

  async function updatePlan(plan: DcPlan, cycle?: DcPlanCycle) {
    const config = useRuntimeConfig();
    let targetPlanId = "";

    if (plan === DcPlan.Trial)
      targetPlanId = config.public.productTrialId;
    else if (plan === DcPlan.Essential && cycle === DcPlanCycle.Monthly)
      targetPlanId = config.public.productEssentialMonthlyId;
    else if (plan === DcPlan.Essential && cycle === DcPlanCycle.Annual)
      targetPlanId = config.public.productEssentialAnnualId;
    else if (plan === DcPlan.Pro && cycle === DcPlanCycle.Monthly)
      targetPlanId = config.public.productProMonthlyId;
    else if (plan === DcPlan.Pro && cycle === DcPlanCycle.Annual)
      targetPlanId = config.public.productProAnnualId;
    else throw createError("Selected plan or cycle is invalid.");

    const endpoint = DcEndPoints.UpdatePlan({ targetPlanId });
    const response = await useApi(endpoint);

    if (!response.success) {
      throw createError(response.message
        ?? t("common.genericError"));
    }

    return response.content?.redirectUrl;
  }

  async function cancelPlan() {
    const endpoint = DcEndPoints.CancelSubscription();
    const response = await useApi(endpoint);

    if (!response.success) {
      throw createError(response.message
        ?? t("common.genericError"));
    }
  }

  // Utils
  /**
   * Gets the core requests that need to be fetched after authentication before the app can be used.
   */
  function getCoreRequestsPostAuth() {
    // We fetch chatbots and documents because we need documents in chatbot edit routes
    const requests = [
      useChatbotStore().fetchChatbots(),
      useDocumentStore().fetchDocuments(),
    ];

    if (useLimits().canUseTeamFeatures.value && user.value?.organizationUserRole !== OrganizationUserRole.Member)
      requests.push(fetchTeamMembers());

    return requests;
  }

  function $reset() {
    isUserDataFetched.value = false;
    user.value = undefined;
    organization.value = undefined;
    organizationUsers.value = undefined;
    plan.value = undefined;
    usage.value = undefined;
    planCycleEndsAt.value = undefined;
    planWillRenew.value = undefined;
  }

  return {
    isAuthenticated,
    isUserDataFetched,

    user,
    organization,
    organizationUsers,
    plan,
    usage,
    planCycleEndsAt,
    planWillRenew,

    avatarUrl,
    isPlanExpired,
    isPlanAnnual,
    isOnTrial,
    isOnCustomPlan,
    isAdmin,
    canUseApp,

    fetchUserData,
    fetchAccountUsages,
    fetchTeamMembers,
    resetPassword,

    getBillingPortalUrl,
    updatePlan,
    cancelPlan,

    getCoreRequestsPostAuth,
    $reset,
  };
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
